<template>
  <section class="contact-content">
    <h1>{{ translate.lv.component.contact.name }}</h1>

    <div class="contact-description" v-html="translate.lv.component.contact.description"></div>
  </section>
</template>

<script>
// Data
import Translate from '@/data/translate.json'

export default {
  name: 'Contact',
  data () {
    return {
      translate: Translate
    }
  }
}
</script>

<style scoped>

</style>
