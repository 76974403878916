<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  data () {
    return {
      visible: true,
      some_text: ''
    }
  }
}
</script>
