<template>
  <section class="product-content">
    <h1>Produkts #{{ productId }}</h1>

    <div class="product-block">
      <div class="product-description">
        <p>Produkta apraksts</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Product',
  data () {
    return {

    }
  },
  computed: {
    productId: function () {
      return this.$route.params.productId
    }
  }
}
</script>

<style scoped>

</style>
