<template>
  <section class="catalog-content">
    <h1>Preces un pakalpojumi #{{ catalogId }}</h1>

    <div class="row child-list">
      <b-col lg="12" v-for="(listProduct, index) in listProducts" :key="index">
        <div class="child-item">
          <a :href="urlPage + listProduct.url">{{ listProduct.name }}</a>
        </div>
      </b-col>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Children',
  data () {
    return {
      urlPage: '',
      listProducts: [
        {
          url: '1',
          name: 'CI105P.1 Asmeņserveru uzstādīšanas un konfigurēšanas pakalpojumi un konsultācijas'
        },
        {
          url: '2',
          name: 'CI105P.1 Asmeņserveru uzstādīšanas un konfigurēšanas pakalpojumi un konsultācijas'
        },
        {
          url: '3',
          name: 'CI105P.1 Asmeņserveru uzstādīšanas un konfigurēšanas pakalpojumi un konsultācijas'
        },
        {
          url: '4',
          name: 'CI105P.1 Asmeņserveru uzstādīšanas un konfigurēšanas pakalpojumi un konsultācijas'
        },
        {
          url: '5',
          name: 'CI105P.1 Asmeņserveru uzstādīšanas un konfigurēšanas pakalpojumi un konsultācijas'
        },
        {
          url: '6',
          name: 'CI105P.1 Asmeņserveru uzstādīšanas un konfigurēšanas pakalpojumi un konsultācijas'
        },
        {
          url: '7',
          name: 'CI105P.1 Asmeņserveru uzstādīšanas un konfigurēšanas pakalpojumi un konsultācijas'
        },
        {
          url: '8',
          name: 'CI105P.1 Asmeņserveru uzstādīšanas un konfigurēšanas pakalpojumi un konsultācijas'
        },
        {
          url: '9',
          name: 'CI105P.1 Asmeņserveru uzstādīšanas un konfigurēšanas pakalpojumi un konsultācijas'
        },
        {
          url: '10',
          name: 'CI105P.1 Asmeņserveru uzstādīšanas un konfigurēšanas pakalpojumi un konsultācijas'
        }
      ]
    }
  },
  mounted: function () {
    this.$nextTick(function () {
      this.urlPage = window.location.href + '/'
    })
  },
  computed: {
    catalogId: function () {
      return this.$route.params.catalogId
    }
  }
}
</script>

<style scoped>

</style>
