<template>
  <div id="home" class="home">
    <header>
      <b-container>
        <TopMenu/>

        <b-row>
          <b-col lg="6" class="header-information" data-aos="fade-up" data-aos-delay="200">
            <h4>{{ translate.lv.home_page.header_info.title }}</h4>
            <p>{{ translate.lv.home_page.header_info.description }}</p>
            <a href="javascript:void(0)">{{ translate.lv.home_page.header_info.link }}</a>
          </b-col>
        </b-row>
      </b-container>
    </header>

    <div class="plus-block">
      <b-container>
        <b-row>
          <div class="plus-description" data-aos="fade-up" data-aos-delay="200">
            <h1>{{ translate.lv.home_page.plus_description.title }}</h1>
            <ul v-html="translate.lv.home_page.plus_description.list"></ul>
          </div>

          <div class="plus-list">
            <div v-for="(item, index) in plusItem.slice(0, 3)" :key="index" :id="'plus-item-' + (index + 1)" class="plus-item" data-aos="fade-up" data-aos-delay="200">
              <div class="plus-background"></div>
              <div class="plus-info">
                <img :src="'/images/icons/icon_plus_' + (index + 1) + '.png'" v-on:mouseover="activeItem('plus-item-' + (index + 1), 'plus-item')">
                <span>{{ item }}</span>
              </div>
            </div>
          </div>
          <div class="plus-list plus-list__2">
            <div v-for="(item, index) in plusItem.slice(3, 6)" :key="index" :id="'plus-item-' + (index + 4)" class="plus-item" data-aos="fade-up" data-aos-delay="100">
              <div class="plus-background"></div>
              <div class="plus-info">
                <img :src="'/images/icons/icon_plus_' + (index + 4) + '.png'" v-on:mouseover="activeItem('plus-item-' + (index + 4), 'plus-item')">
                <span>{{ item }}</span>
              </div>
            </div>
          </div>
          <div class="plus-list plus-list__3">
            <div v-for="(item, index) in plusItem.slice(6, 8)" :key="index" :id="'plus-item-' + (index + 7)" class="plus-item" data-aos="fade-up" data-aos-delay="200">
              <div class="plus-background"></div>
              <div class="plus-info">
                <img :src="'/images/icons/icon_plus_' + (index + 7) + '.png'" v-on:mouseover="activeItem('plus-item-' + (index + 7), 'plus-item')">
                <span>{{ item }}</span>
              </div>
            </div>
          </div>
        </b-row>
      </b-container>
    </div>

    <b-container class="registration-block" data-aos="fade-up" data-aos-delay="200">
      <b-row>
        <b-col lg="8" class="registration-title">
          <h2>{{ translate.lv.home_page.registration_block.title }}</h2>
        </b-col>
        <b-col lg="4" class="registration-button">
          <a :href="links.path['auth']">{{ translate.lv.auth }}</a>
        </b-col>
      </b-row>
    </b-container>

    <b-carousel id="carousel"
      class="carousel-block"
      fade
      controls
      indicators
      background="#28282e"
      padding="100px 0px 35px"
      data-aos="fade-up"
      data-aos-delay="200">
      <b-carousel-slide v-for="(item,index) in carouselData" :key="index">
        <template #img>
          <b-container>
            <b-row>
              <b-col lg="6">
                <h3>{{ item.title }}</h3>
                <div class="carousel-description" v-html="item.description"></div>
                <a class="carousel-link" :href="links.path['auth']">{{ translate.lv.auth }}</a>
              </b-col>

              <b-col lg="6" class="carousel-image">
                <img src="../assets/images/imac.png" alt="Imac">
              </b-col>
            </b-row>
          </b-container>
        </template>
      </b-carousel-slide>
    </b-carousel>

    <b-container class="catalog-block" data-aos="fade-up" data-aos-delay="200">
      <b-row>
        <b-col lg="4">
          <h4>{{ translate.lv.catalog }}</h4>
        </b-col>
        <b-col lg="8">
          <span>{{ translate.lv.home_page.catalog_block.description }}</span>
        </b-col>
      </b-row>

      <b-table responsive :items="items" :fields="fields" class="table-list"></b-table>

      <b-container class="banner-block">
        <b-row>
          <b-col lg="12" class="title">{{ translate.lv.home_page.catalog_block.bb.title }}</b-col>
          <b-col lg="6" class="description">{{ translate.lv.home_page.catalog_block.bb.description }}</b-col>
        </b-row>
      </b-container>

      <b-container>
        <b-row>
          <b-col class="link-catalog">
            <a href="#">{{ translate.lv.home_page.catalog_block.lc.link }}</a>
          </b-col>
        </b-row>
      </b-container>
    </b-container>

    <div class="rate-block" data-aos="fade-up" data-aos-delay="200">
      <b-container class="rate-description">
        <b-row>
          <b-col lg="7">
            <h4>{{ translate.lv.home_page.rate_description.title }}</h4>
          </b-col>
          <b-col lg="5">
            <div v-html="translate.lv.home_page.rate_description.description"></div>
          </b-col>
        </b-row>
      </b-container>

      <b-container class="rate-list">
        <b-row>
          <b-card v-for="(rate, index) in rateData" :key="index" :id="'rate-' + (index+1)" class="col-lg-4 rent" :title="rate.title" v-on:mouseover="activeItem('rate-' + (index+1), 'rent')">
            <b-card-text :class="rate.color">{{ rate.price }}{{ currency }}</b-card-text>
            <b-list-group v-for="(item, ind) in rate.options" :key="ind">
              <b-list-group-item>{{ item }}</b-list-group-item>
            </b-list-group>
            <b-button :class="'btn ' + rate.color ">PURCHASE</b-button>
          </b-card>
        </b-row>
      </b-container>
    </div>

    <Footer/>
  </div>
</template>

<script>
// Components
import TopMenu from '../components/modules/TopMenu'
import Footer from '../components/Footer'

// Data
import Translate from '../data/translate.json'
import Links from '../router/links'

export default {
  name: 'Home',
  data () {
    return {
      translate: Translate,
      links: Links,
      currency: '$',
      plusItem: Translate.lv.home_page.plus_list,
      carouselData: Translate.lv.home_page.carousel_slide,
      rateData: Translate.lv.home_page.rate_list,
      fields: [
        { key: 'number', label: Translate.lv.component.product_list.item },
        { key: 'description', label: Translate.lv.component.product_list.specification },
        { key: 'price', label: Translate.lv.component.product_list.price }
      ],
      items: [
        {
          number: 'TM50.1.1',
          description: 'Apliecības formāts: A4 (210 x 297 mm)/ Krāsa: cmyk+0/ Apliecības papīrs: Amber Preprint vai ekvivalents* 100 g/m²/ * Ekvivalences prasības: masa 100 g/m² ±4%, biezums 130 mcr ±4%, raupjums 200 ml/min ±60, caurspīdīgums (ISO 2471) ne vairāk kā 96 ±2%, baltums 167 CIE ±4%, gaišums (ISO 2470/D65) 110%/ Pilnais Latvijas valsts ģerbonis augšējā daļā ar tekstu "LATVIJAS REPUBLIKA": drukāts pilnkrāsu drukā, atbilstošās krāsās saskaņā ar likumu "Par Latvijas valsts ģerboni". Ģerboņa izmērs h 25 mm.',
          price: 'ar PVN: 0.36 EUR bez PVN: 0.30 EUR'
        },
        {
          number: 'TM50.1.1',
          description: 'Apliecības formāts: A4 (210 x 297 mm)/ Krāsa: cmyk+0/ Apliecības papīrs: Amber Preprint vai ekvivalents* 100 g/m²/ * Ekvivalences prasības: masa 100 g/m² ±4%, biezums 130 mcr ±4%, raupjums 200 ml/min ±60, caurspīdīgums (ISO 2471) ne vairāk kā 96 ±2%, baltums 167 CIE ±4%, gaišums (ISO 2470/D65) 110%/ Pilnais Latvijas valsts ģerbonis augšējā daļā ar tekstu "LATVIJAS REPUBLIKA": drukāts pilnkrāsu drukā, atbilstošās krāsās saskaņā ar likumu "Par Latvijas valsts ģerboni". Ģerboņa izmērs h 25 mm.',
          price: 'ar PVN: 0.36 EUR bez PVN: 0.30 EUR'
        },
        {
          number: 'TM50.1.1',
          description: 'Apliecības formāts: A4 (210 x 297 mm)/ Krāsa: cmyk+0/ Apliecības papīrs: Amber Preprint vai ekvivalents* 100 g/m²/ * Ekvivalences prasības: masa 100 g/m² ±4%, biezums 130 mcr ±4%, raupjums 200 ml/min ±60, caurspīdīgums (ISO 2471) ne vairāk kā 96 ±2%, baltums 167 CIE ±4%, gaišums (ISO 2470/D65) 110%/ Pilnais Latvijas valsts ģerbonis augšējā daļā ar tekstu "LATVIJAS REPUBLIKA": drukāts pilnkrāsu drukā, atbilstošās krāsās saskaņā ar likumu "Par Latvijas valsts ģerboni". Ģerboņa izmērs h 25 mm.',
          price: 'ar PVN: 0.36 EUR bez PVN: 0.30 EUR'
        }
      ]
    }
  },
  components: {
    TopMenu,
    Footer
  },
  methods: {
    // Add class active for item in list
    activeItem (id, className) {
      const item = document.getElementById(id)
      const list = document.querySelectorAll('.' + className)

      list.forEach(e => {
        e.classList.remove('active')
      })

      item.classList.add('active')
    }
  },
  mounted: function () {
    const activeItem = document.getElementById('rate-2')
    activeItem.classList.add('active')
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
header {
  background-image: url('../assets/images/header_fone.png');
  background-size: 100%;
  background-repeat: no-repeat;
  max-width: 100%;
  height: 705px;
  padding: 25px 0px;
}
</style>
