// Modules
import Vue from 'vue'
import Router from 'vue-router'

// Pages
import Home from '../pages/Home.vue'
import Catalog from '../pages/Catalog.vue'
import Account from '../pages/Account.vue'
import Blog from '../pages/Blog'
import Page from '../pages/Page.vue'

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/',
      component: Home,
      meta: {
        breadCrumb: 'Home'
      }
    },
    {
      path: '/catalog',
      component: Catalog,
      meta: {
        breadCrumb: 'Catalog'
      },
      children: [
        {
          path: '/catalog/:catalogId',
          component: Catalog,
          props: true,
          children: [
            {
              path: '/catalog/:catalogId/:productListId',
              component: Catalog,
              props: true,
              children: [
                {
                  path: '/catalog/:catalogId/:productListId/:productId',
                  component: Catalog,
                  props: true
                }
              ]
            }
          ]
        }
      ]
    },
    {
      path: '/my-profile',
      component: Account,
      meta: {
        breadCrumb: 'My profile'
      }
    },
    {
      path: '/my-products',
      component: Account,
      meta: {
        breadCrumb: 'My products'
      }
    },
    {
      path: '/statistics',
      component: Account,
      meta: {
        breadCrumb: 'Statistics'
      }
    },
    {
      path: '/blog',
      component: Blog,
      meta: {
        breadCrumb: 'Blog'
      },
      children: [
        {
          path: '/blog/:blogId',
          component: Blog,
          props: true
        }
      ]
    },
    {
      path: '/contact',
      component: Page,
      meta: {
        breadCrumb: 'Contacts'
      }
    },
    {
      path: '/terms',
      component: Page,
      meta: {
        breadCrumb: 'Terms of use'
      }
    },
    {
      path: '/policy',
      component: Page,
      meta: {
        breadCrumb: 'Privacy policy'
      }
    },
    {
      path: '/404',
      component: Page,
      props: true
    },
    {
      path: '*',
      redirect: '/404'
    }
  ]
})

export default router
