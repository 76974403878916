<template>
  <div>
    <ul class="breadcrumb-block">
      <li v-for="(crumb, index) in crumbs" :key="index">
        <a v-if="index !== (crumbs.length-1)" :href="crumb.to">{{ crumb.text }}</a>
        <span v-else>{{ crumb.text }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
// Data
import Translate from '../../data/translate.json'

export default {
  name: 'Breadcrumbs',
  data () {
    return {
      translate: Translate
    }
  },
  computed: {
    crumbs: function () {
      const urlPage = window.location.origin + '/#'
      const home = {
        path: '',
        to: urlPage + '/',
        text: this.translate.lv.home
      }
      const pathArray = this.$route.path.split('/')
      pathArray.shift()
      const breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
        let breadcrumbsArray
        if (breadcrumbArray[idx - 3]) {
          breadcrumbsArray = urlPage + '/' + breadcrumbArray[idx - 3].path + '/' + breadcrumbArray[idx - 2].path + '/' + breadcrumbArray[idx - 1].path + '/' + path
        } else if (breadcrumbArray[idx - 2]) {
          breadcrumbsArray = urlPage + '/' + breadcrumbArray[idx - 2].path + '/' + breadcrumbArray[idx - 1].path + '/' + path
        } else if (breadcrumbArray[idx - 1]) {
          breadcrumbsArray = urlPage + '/' + breadcrumbArray[idx - 1].path + '/' + path
        } else {
          breadcrumbsArray = urlPage + '/' + path
        }

        breadcrumbArray.push({
          path: path,
          to: breadcrumbsArray,
          text: this.translate.lv[path.replace('-', '_')] || path
        })
        return breadcrumbArray
      }, [])

      // Add home page in Object
      breadcrumbs.unshift(home)

      return breadcrumbs
    }
  }
}
</script>

<style scoped>

</style>
