<template>
  <header class="top-header">
    <b-container>
      <TopMenu :urlCurrentPage="this.$route.path"/>
    </b-container>
  </header>
</template>

<script>
import TopMenu from './modules/TopMenu'

export default {
  name: 'Header',
  data () {
    return {
      urlCurrentPage: ''
    }
  },
  components: {
    TopMenu
  }
}
</script>

<style scoped>

</style>
