<template>
  <section class="productList-content">
    <h1>Preces un pakalpojumi #{{ productListId }}</h1>

    <table responsive class="row list">
      <tr>
        <th class="col-lg-2">{{ translate.lv.component.product_list.item }}:</th>
        <th class="col-lg-7">{{ translate.lv.component.product_list.specification }}</th>
        <th class="col-lg-3">{{ translate.lv.component.product_list.price }}</th>
      </tr>
      <tr v-for="(product, index) in products" :key="index">
        <td class="col-lg-2">
          <a :href="urlPage + product.url">{{ product.number }}</a>
        </td>
        <td class="col-lg-7">
          <span>{{ product.description }}</span>
        </td>
        <td class="col-lg-3">
          <p>{{ translate.lv.component.product_list.on_vat }}: <b>{{ product.priceSale }} {{ current }}</b></p>
          <p>{{ translate.lv.component.product_list.off_vat }}: {{ product.price }} {{ current }}</p>
        </td>
      </tr>
    </table>
  </section>
</template>

<script>
// Data
import Translate from '@/data/translate.json'

export default {
  name: 'ProductList',
  data () {
    return {
      translate: Translate,
      urlPage: '',
      current: 'EUR',
      products: [
        {
          url: '1',
          number: 'TM50.1.1',
          description: 'Apliecības formāts: A4 (210 x 297 mm)/ Krāsa: cmyk+0/ Apliecības papīrs: Amber Preprint vai ekvivalents* 100 g/m²/ * Ekvivalences prasības: masa 100 g/m² ±4%, biezums 130 mcr ±4%, raupjums 200 ml/min ±60, caurspīdīgums (ISO 2471) ne vairāk kā 96 ±2%, baltums 167 CIE ±4%, gaišums (ISO 2470/D65) 110%/ Pilnais Latvijas valsts ģerbonis augšējā daļā ar tekstu "LATVIJAS REPUBLIKA": drukāts pilnkrāsu drukā, atbilstošās krāsās saskaņā ar likumu "Par Latvijas valsts ģerboni". Ģerboņa izmērs h 25 mm.',
          priceSale: '0.36',
          price: '0.30'
        },
        {
          url: '2',
          number: 'TM50.1.2',
          description: 'Apliecības formāts: A4 (210 x 297 mm)/ Krāsa: cmyk+0/ Apliecības papīrs: Amber Preprint vai ekvivalents* 100 g/m²/ * Ekvivalences prasības: masa 100 g/m² ±4%, biezums 130 mcr ±4%, raupjums 200 ml/min ±60, caurspīdīgums (ISO 2471) ne vairāk kā 96 ±2%, baltums 167 CIE ±4%, gaišums (ISO 2470/D65) 110%/ Pilnais Latvijas valsts ģerbonis augšējā daļā ar tekstu "LATVIJAS REPUBLIKA": drukāts pilnkrāsu drukā, atbilstošās krāsās saskaņā ar likumu "Par Latvijas valsts ģerboni". Ģerboņa izmērs h 25 mm.',
          priceSale: '0.38',
          price: '0.32'
        },
        {
          url: '3',
          number: 'TM50.1.3',
          description: 'Apliecības formāts: A4 (210 x 297 mm)/ Krāsa: cmyk+0/ Apliecības papīrs: Amber Preprint vai ekvivalents* 100 g/m²/ * Ekvivalences prasības: masa 100 g/m² ±4%, biezums 130 mcr ±4%, raupjums 200 ml/min ±60, caurspīdīgums (ISO 2471) ne vairāk kā 96 ±2%, baltums 167 CIE ±4%, gaišums (ISO 2470/D65) 110%/ Pilnais Latvijas valsts ģerbonis augšējā daļā ar tekstu "LATVIJAS REPUBLIKA": drukāts pilnkrāsu drukā, atbilstošās krāsās saskaņā ar likumu "Par Latvijas valsts ģerboni". Ģerboņa izmērs h 25 mm.',
          priceSale: '0.40',
          price: '0.34'
        },
        {
          url: '4',
          number: 'TM50.1.4',
          description: 'Apliecības formāts: A4 (210 x 297 mm)/ Krāsa: cmyk+0/ Apliecības papīrs: Amber Preprint vai ekvivalents* 100 g/m²/ * Ekvivalences prasības: masa 100 g/m² ±4%, biezums 130 mcr ±4%, raupjums 200 ml/min ±60, caurspīdīgums (ISO 2471) ne vairāk kā 96 ±2%, baltums 167 CIE ±4%, gaišums (ISO 2470/D65) 110%/ Pilnais Latvijas valsts ģerbonis augšējā daļā ar tekstu "LATVIJAS REPUBLIKA": drukāts pilnkrāsu drukā, atbilstošās krāsās saskaņā ar likumu "Par Latvijas valsts ģerboni". Ģerboņa izmērs h 25 mm.',
          priceSale: '0.42',
          price: '0.36'
        },
        {
          url: '5',
          number: 'TM50.1.5',
          description: 'Apliecības formāts: A4 (210 x 297 mm)/ Krāsa: cmyk+0/ Apliecības papīrs: Amber Preprint vai ekvivalents* 100 g/m²/ * Ekvivalences prasības: masa 100 g/m² ±4%, biezums 130 mcr ±4%, raupjums 200 ml/min ±60, caurspīdīgums (ISO 2471) ne vairāk kā 96 ±2%, baltums 167 CIE ±4%, gaišums (ISO 2470/D65) 110%/ Pilnais Latvijas valsts ģerbonis augšējā daļā ar tekstu "LATVIJAS REPUBLIKA": drukāts pilnkrāsu drukā, atbilstošās krāsās saskaņā ar likumu "Par Latvijas valsts ģerboni". Ģerboņa izmērs h 25 mm.',
          priceSale: '0.44',
          price: '0.38'
        },
        {
          url: '6',
          number: 'TM50.1.6',
          description: 'Apliecības formāts: A4 (210 x 297 mm)/ Krāsa: cmyk+0/ Apliecības papīrs: Amber Preprint vai ekvivalents* 100 g/m²/ * Ekvivalences prasības: masa 100 g/m² ±4%, biezums 130 mcr ±4%, raupjums 200 ml/min ±60, caurspīdīgums (ISO 2471) ne vairāk kā 96 ±2%, baltums 167 CIE ±4%, gaišums (ISO 2470/D65) 110%/ Pilnais Latvijas valsts ģerbonis augšējā daļā ar tekstu "LATVIJAS REPUBLIKA": drukāts pilnkrāsu drukā, atbilstošās krāsās saskaņā ar likumu "Par Latvijas valsts ģerboni". Ģerboņa izmērs h 25 mm.',
          priceSale: '0.46',
          price: '0.40'
        },
        {
          url: '7',
          number: 'TM50.1.7',
          description: 'Apliecības formāts: A4 (210 x 297 mm)/ Krāsa: cmyk+0/ Apliecības papīrs: Amber Preprint vai ekvivalents* 100 g/m²/ * Ekvivalences prasības: masa 100 g/m² ±4%, biezums 130 mcr ±4%, raupjums 200 ml/min ±60, caurspīdīgums (ISO 2471) ne vairāk kā 96 ±2%, baltums 167 CIE ±4%, gaišums (ISO 2470/D65) 110%/ Pilnais Latvijas valsts ģerbonis augšējā daļā ar tekstu "LATVIJAS REPUBLIKA": drukāts pilnkrāsu drukā, atbilstošās krāsās saskaņā ar likumu "Par Latvijas valsts ģerboni". Ģerboņa izmērs h 25 mm.',
          priceSale: '0.48',
          price: '0.42'
        }
      ]
    }
  },
  mounted: function () {
    this.$nextTick(function () {
      this.urlPage = window.location.href + '/'
    })
  },
  computed: {
    productListId: function () {
      return this.$route.params.productListId
    }
  },
  methods: {
    productInfo (productName, price) {
      return alert(`Product ${productName}.Information about this product. Price: ${price} ${this.current}`)
    }
  }
}
</script>

<style scoped>

</style>
