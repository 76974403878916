// Modules
import Vue from 'vue'
import VueRouter from 'vue-router'
import BootstrapVue from 'bootstrap-vue'
import AOS from 'aos'

// Data
import router from './router'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'aos/dist/aos.css'
import './assets/scss/style.scss'
import './assets/scss/responsive.scss'

// Pages
import App from './App.vue'

Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(BootstrapVue)

new Vue({
  created () {
    AOS.init()
  },
  el: '#app',
  template: '<App/>',
  components: { App },
  router
}).$mount('#app')
