<template>
  <b-row>
    <b-col lg="6" class="header-logo" data-aos="fade-up" data-aos-delay="200">
      <a :href="links.path['home']">
        <img src="../../assets/images/logo_small.png" :alt="translate['lv']['eisstat']">
      </a>
    </b-col>

    <b-col lg="6" class="header-menu" data-aos="fade-up" data-aos-delay="200">
      <b-navbar toggleable class="navbar-dark">
        <ul class="desctop">
          <b-nav-item v-for="(link, index) in topMenu"
            :key="index"
            :class="link.class + ' ' + activeClass(link.key)"
            :href="link.path[link.key]">{{ link.title }}</b-nav-item>
        </ul>

        <div class="mobile">
          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-form>
                <li class="nav-item" v-for="(link, index) in topMenu" :key="index">
                  <a class="nav-link nav-link-icon" :class="activeClass(link.key)" :href="links.path[link.key]">
                    <i class="ni ni-favourite-28"></i>
                    <span class="nav-link-inner--text d-lg-none">{{ link.title }}</span>
                  </a>
                </li>
              </b-nav-form>
            </b-navbar-nav>
          </b-collapse>
        </div>
      </b-navbar>
    </b-col>
  </b-row>
</template>

<script>
// Data
import Links from '../../router/links.json'
import Translate from '../../data/translate.json'

export default {
  name: 'TopMenu',
  props: ['urlCurrentPage'],
  data () {
    return {
      links: Links,
      translate: Translate,
      currentLink: window.location.href,
      topMenu: ''
    }
  },
  mounted () {
    this.topMenu = [
      { key: 'home', path: this.links.path, title: this.translate.lv.home, class: '' },
      { key: 'catalog', path: this.links.path, title: this.translate.lv.catalog, class: '' },
      { key: 'blog', path: this.links.path, title: this.translate.lv.blog, class: '' },
      { key: 'contact', path: this.links.path, title: this.translate.lv.contact, class: '' },
      { key: 'auth', path: this.links.path, title: this.translate.lv.auth, class: 'auth' }
    ]
  },
  methods: {
    activeClass (segment) {
      if (Boolean(this.urlCurrentPage) === true) {
        this.currentLink = this.urlCurrentPage
      }

      if (this.currentLink.indexOf(segment) >= 0) {
        return 'active'
      } else {
        return ''
      }
    }
  }
}
</script>

<style scoped>

</style>
