<template>
  <section class="catalog-content">
    <h1>Preces un pakalpojumi</h1>

    <div class="row catalog-list">
      <b-col lg="6" v-for="(category, index) in categories" :key="index">
        <div class="catalog-item">
          <a :href="urlPage + category.url">
            <div class="icon">
              <img v-bind:src="iconParent" alt="">
            </div>
            <div class="info">
              <p>{{ category.name }}</p>
              <span>{{ translate.lv.component.parent.prefix_date }}: {{ category.actuality }}</span>
            </div>
          </a>
        </div>
      </b-col>
    </div>
  </section>
</template>

<script>
// Data
import Translate from '@/data/translate.json'

export default {
  name: 'Parent',
  data () {
    return {
      translate: Translate,
      urlPage: '',
      iconParent: require('../../../assets/images/icons/icon_bulb.png'),
      categories: [
        {
          url: '1',
          name: 'CI105P Asmeņserveru un datu glabātuvju pakalpojumi',
          actuality: '12.10.2017 – 11.10.2021'
        },
        {
          url: '2',
          name: 'CI105P Asmeņserveru un datu glabātuvju pakalpojumi',
          actuality: '12.11.2017 – 11.11.2021'
        },
        {
          url: '3',
          name: 'CI105P Asmeņserveru un datu glabātuvju pakalpojumi',
          actuality: '12.12.2017 – 11.12.2021'
        },
        {
          url: '4',
          name: 'CI105P Asmeņserveru un datu glabātuvju pakalpojumi',
          actuality: '12.10.2018 – 11.10.2021'
        },
        {
          url: '5',
          name: 'CI105P Asmeņserveru un datu glabātuvju pakalpojumi',
          actuality: '12.10.2019 – 11.10.2021'
        },
        {
          url: '6',
          name: 'CI105P Asmeņserveru un datu glabātuvju pakalpojumi',
          actuality: '12.10.2020 – 11.10.2021'
        },
        {
          url: '7',
          name: 'CI105P Asmeņserveru un datu glabātuvju pakalpojumi',
          actuality: '12.10.2017 – 11.10.2021'
        },
        {
          url: '8',
          name: 'CI105P Asmeņserveru un datu glabātuvju pakalpojumi',
          actuality: '12.10.2017 – 11.10.2021'
        },
        {
          url: '9',
          name: 'CI105P Asmeņserveru un datu glabātuvju pakalpojumi',
          actuality: '12.10.2017 – 11.10.2021'
        },
        {
          url: '10',
          name: 'CI105P Asmeņserveru un datu glabātuvju pakalpojumi',
          actuality: '12.10.2017 – 11.10.2021'
        }
      ]
    }
  },
  mounted: function () {
    this.$nextTick(() => {
      this.urlPage = window.location.href + '/'
    })
  }
}
</script>

<style scoped>

</style>
