<template>
  <div class="content">
    <router-view></router-view>
  </div>
</template>

<script>
import Vue from 'vue'
import VueRouter from 'vue-router'

// Components catalog
import Parent from './contents/catalog/Parent'
import Children from './contents/catalog/Children'
import ProductList from './contents/catalog/ProductList'
import Product from './contents/catalog/Product'

// Components account
import Profile from './contents/account/Profile'
import MyProducts from './contents/account/MyProducts'
import Statistics from './contents/account/Statistics'

// Components blog
import ListBlog from './contents/blog/ListBlog'
import ItemBlog from './contents/blog/ItemBlog'

// Components page
import Contact from './contents/page/Contact'
import Terms from './contents/page/Terms'
import Policy from './contents/page/Policy'

// Components errors
import NotFound from './contents/errors/404'

Vue.use(VueRouter)

const routes = [
  { path: '/catalog', name: 'Parent', component: Parent },
  { path: '/catalog/:catalogId', name: 'Children', component: Children },
  { path: '/catalog/:catalogId/:productListId', name: 'ProductList', component: ProductList },
  { path: '/catalog/:catalogId/:productListId/:productId', name: 'Product', component: Product },
  { path: '/my-profile', name: 'Profile', component: Profile },
  { path: '/my-products', name: 'MyProducts', component: MyProducts },
  { path: '/statistics', name: 'Statistics', component: Statistics },
  { path: '/contact', name: 'Contact', component: Contact },
  { path: '/terms', name: 'Terms', component: Terms },
  { path: '/policy', name: 'Policy', component: Policy },
  { path: '/blog', name: 'ListBlog', component: ListBlog },
  { path: '/blog/:blogId', name: 'ItemBlog', component: ItemBlog },
  { path: '/404', name: 'NotFound', component: NotFound }
]

const router = new VueRouter({
  routes
})

export default {
  name: 'Content',
  router
}
</script>

<style scoped>
.hide {
  display: none;
}
</style>
