<template>
  <section class="footer-menu">
    <b-container>
      <b-row>
        <b-col lg="3">
          <span>{{ translate.lv.copyrate }}</span>
        </b-col>
        <b-col lg="3">
          <a class="email-link" :href="`mailto:${translate.lv.emailCompany}`">{{ translate.lv.emailCompany }}</a>
        </b-col>
        <b-col lg="3" v-for="(link, index) in bottomMenu" :key="index">
          <a :class="activeClass(link.key)" :href="links.path[link.key]">{{ link.title }}</a>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
// Data
import Links from '@/router/links.json'
import Translate from '../../data/translate.json'

export default {
  name: 'BottomMenu',
  props: ['urlCurrentPage'],
  data () {
    return {
      links: Links,
      translate: Translate,
      currentLink: this.$route.path,
      bottomMenu: ''
    }
  },
  mounted: function () {
    this.bottomMenu = [
      { key: 'terms', path: this.links.path, title: this.translate.lv.terms },
      { key: 'policy', path: this.links.path, title: this.translate.lv.policy }
    ]
  },
  methods: {
    activeClass (segment) {
      if (Boolean(this.urlCurrentPage) === true) {
        this.currentLink = this.urlCurrentPage
      }

      if (this.currentLink.indexOf(segment) > 0) {
        return 'active'
      } else {
        return ''
      }
    }
  }
}
</script>

<style scoped>

</style>
