<template>
  <ul class="left-menu">
    <li v-for="(link, index) in leftMenu" :key="index">
      <a :class="activeClass(link.key)" :href="link.path[link.key]">{{ link.title }}</a>
    </li>
  </ul>
</template>

<script>
// Configuration
import Links from '../../router/links.json'
import Translate from '../../data/translate.json'

export default {
  name: 'LeftMenu',
  props: ['urlCurrentPage'],
  data () {
    return {
      links: Links,
      translate: Translate,
      leftMenu: '',
      currentLink: this.$route.path
    }
  },
  mounted: function () {
    this.leftMenu = [
      { key: 'profile', path: this.links.path.account, title: this.translate.lv.my_profile },
      { key: 'my-products', path: this.links.path.account, title: this.translate.lv.my_products },
      { key: 'catalog', path: this.links.path, title: this.translate.lv.catalog },
      { key: 'statistics', path: this.links.path.account, title: this.translate.lv.statistics }
    ]
  },
  methods: {
    activeClass (segment) {
      if (Boolean(this.urlCurrentPage) === true) {
        this.currentLink = this.urlCurrentPage
      }

      if (this.currentLink.indexOf(segment) > 0) {
        return 'active'
      } else {
        return ''
      }
    }
  }
}
</script>

<style scoped>

</style>
