<template>
  <div id="catalog" class="catalog">
    <Header/>
    <b-container>
      <b-row>
        <b-col lg="4">
          <section class="left-sidebar">
            <Breadcrumbs/>
            <div class="auth-block__left">
              <div class="row">
                <b-col lg="6" col class="left-block"><span>{{ translate.lv.hello }}, <bdo>{{ translate.lv.guest }}</bdo></span></b-col>
                <b-col lg="6" col class="right-block"><a :href="links.path['auth']">{{ translate.lv.auth }}</a></b-col>
              </div>
            </div>

            <div class="search-block">
              <input type="search" :placeholder="`${translate.lv.search} ...`">
            </div>

            <LeftMenu :urlCurrentPage="this.$route.path"/>

            <div class="region-block">
              <span>{{ translate.lv.choiceRegion }}:</span>
              <select name="region" id="select-region" class="select">
                <option :value="translate.lv.cities.riga.code">{{ translate.lv.cities.riga.name }}</option>
                <option :value="translate.lv.cities.kurzeme.code">{{ translate.lv.cities.kurzeme.name }}</option>
                <option :value="translate.lv.cities.latgale.code">{{ translate.lv.cities.latgale.name }}</option>
                <option :value="translate.lv.cities.vidzeme.code">{{ translate.lv.cities.vidzeme.name }}</option>
                <option :value="translate.lv.cities.zemgale.code">{{ translate.lv.cities.zemgale.name }}</option>
              </select>
            </div>
          </section>
        </b-col>
        <b-col lg="8">
          <ContentBlock></ContentBlock>
        </b-col>
      </b-row>
    </b-container>
    <Footer/>
  </div>
</template>

<script>
// Components
import Header from '../components/Header'
import Breadcrumbs from '../components/modules/Breadcrumbs'
import LeftMenu from '../components/modules/LeftMenu'
import ContentBlock from '../components/Content'
import Footer from '../components/Footer'

// Data
import Links from '../router/links.json'
import Translate from '../data/translate.json'

export default {
  name: 'Catalog',
  data () {
    return {
      links: Links,
      translate: Translate,
      urlCurrentPage: ''
    }
  },
  mounted: () => {
    // Selector region - change arrow
    const selectRegion = document.getElementById('select-region')
    selectRegion.addEventListener('change', () => {
      selectRegion.blur()
    })
  },
  components: {
    Header,
    Breadcrumbs,
    LeftMenu,
    ContentBlock,
    Footer
  }
}
</script>

<style scoped>

</style>
