<template>
  <section class="error-content">
    <h1>{{ translate.lv.component.error.name }} {{ code }}</h1>
    <div class="content-block" v-html="translate.lv.component.error.description"></div>
  </section>
</template>

<script>
// Data
import Translate from '@/data/translate.json'

export default {
  name: 'NotFound',
  data () {
    return {
      translate: Translate,
      code: '404'
    }
  }
}
</script>

<style scoped>

</style>
