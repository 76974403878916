<template>
  <footer>
    <div class="footer-order">
      <b-container>
        <b-row>
          <b-col lg="12">
            <a class="footer-order__link" href="#">{{ translate.lv.trialVersion }}</a>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <BottomMenu :urlCurrentPage="this.$route.path" />
  </footer>
</template>

<script>
// Data
import Links from '../router/links.json'
import Translate from '../data/translate.json'

// Modules
import BottomMenu from './modules/BottomMenu'

export default {
  name: 'Footer',
  data () {
    return {
      links: Links,
      translate: Translate,
      urlCurrentPage: ''
    }
  },
  components: {
    BottomMenu
  }
}
</script>

<style scoped>
.footer-order {
  text-align: center;
  background-color: #cf2a43;
  padding: 30px 0px;
}
.footer-order__link {
  color: #fff;
  text-transform: uppercase;
  font-size: 28px;
  display: inline-block;
}
</style>
