<template>
  <section class="blogList-content">
    <h1>{{ translate.lv.blog }}</h1>

    <ul class="blog-list">
      <li v-for="(post, index) in posts" :key="index">
        <a :href="urlPage + (index+1)">{{ post.name }} {{ index+1 }}</a>
        <p>{{ post.description }}</p>
      </li>
    </ul>
  </section>
</template>

<script>
// Data
import Translate from '@/data/translate.json'

export default {
  name: 'ListBlog',
  data () {
    return {
      translate: Translate,
      urlPage: '',
      posts: [
        {
          name: Translate.lv.blog,
          description: 'Ziņas apraksts'
        },
        {
          name: Translate.lv.blog,
          description: 'Ziņas apraksts'
        },
        {
          name: Translate.lv.blog,
          description: 'Ziņas apraksts'
        },
        {
          name: Translate.lv.blog,
          description: 'Ziņas apraksts'
        },
        {
          name: Translate.lv.blog,
          description: 'Ziņas apraksts'
        },
        {
          name: Translate.lv.blog,
          description: 'Ziņas apraksts'
        },
        {
          name: Translate.lv.blog,
          description: 'Ziņas apraksts'
        },
        {
          name: Translate.lv.blog,
          description: 'Ziņas apraksts'
        },
        {
          name: Translate.lv.blog,
          description: 'Ziņas apraksts'
        }
      ]
    }
  },
  mounted: function () {
    this.$nextTick(() => {
      this.urlPage = window.location.href + '/'
    })
  }
}
</script>

<style scoped>

</style>
