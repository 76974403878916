<template>
  <section class="account-content">
    <h1>{{ translate.lv.component.my_products.name }}</h1>

    <div class="account-description" v-html="translate.lv.component.my_products.description"></div>
  </section>
</template>

<script>
// Default
import Translate from '@/data/translate.json'

export default {
  name: 'MyProducts',
  data () {
    return {
      translate: Translate
    }
  }
}
</script>

<style scoped>

</style>
