<template>
  <section class="info-content">
    <h1>{{ translate.lv.component.policy.name }}</h1>

    <div class="info-description" v-html="translate.lv.component.policy.description"></div>
  </section>
</template>

<script>
// Data
import Translate from '@/data/translate.json'

export default {
  name: 'Policy',
  data () {
    return {
      translate: Translate
    }
  }
}
</script>

<style scoped>

</style>
