<template>
  <div class="blogItem-content">
    <h1>Vienuma emuārs {{ blogId }}</h1>

    <div class="blog-description">
      <p>Šīs ziņas apraksts #{{ blogId }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ItemBlog',
  data () {
    return {
      urlPage: ''
    }
  },
  computed: {
    blogId: function () {
      return this.$route.params.blogId
    }
  }
}
</script>

<style scoped>

</style>
